import React, { useEffect, useState } from "react";
import Button from "../Button";
import { useNavigate } from "react-router";
import { NavigationPaths } from "@/constants/paths";
import {
  calenderIcon,
  CampaignIcon,
  ExitIcon,
  timeIcon,
} from "@/constants/utils/svgs";
import SelectWrapper from "../selectWrapper";
import Image from "../Image";
import Toggle from "react-toggle";
import Modal from "../ExitModal";
import { status as statuses } from "@/constants/status";
import { categories } from "@/constants/categories";
import { types } from "@constants/eventTypes";
import { StepOneProps } from "@/@Interfaces/EventStepOneProps";
import MultiSelectWrapper from "../MultiSelect";

import DatePicker from "react-datepicker";
import TimeRangePicker from "../TimeRangePicker";
import { TicketFormData } from "@/@Interfaces/CampaignProps";

const StepOne: React.FC<StepOneProps> = ({
  setSteps,
  ticket,
  setTicket,
  setDateError,
  validationError,
  dateError,
  setstepOneFormData,
  stepOneFormData,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    category,
    name,
    description,
    status,
    type,
    classification,
    guestLimit,
    externalSiteUrl,
    requiredGuestApproval,
    feedImage,
    pageImage,
    eventAudience,
    eventDate,
    endDate,
    eventTime,
    venueImage,
    maxRSVPs,
  } = stepOneFormData;
  const [urlError, setUrlError] = useState("");
  const [ticketPriceError, setTicketPriceError] = useState<string>("");
  const [ticketNameError, setTicketNameError] = useState<string>("");

  const handleChange = (value: number | string | boolean, name: string) => {
    setstepOneFormData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (type === "Ticketed") {
      handleChange(0, "guestLimit");
    }
  }, [type]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.CAMPAIGNS);
  };

  const handleTicketChange = (field: keyof TicketFormData, value: string) => {
    console.log(field, value);
    if (field === "name") {
      if (value.length > 100) {
        setTicketNameError("Ticket name cannot exceed 100 characters");
        return;
      } else {
        setTicket((prevTicket) => ({
          ...prevTicket,
          [field]: value, // Dynamically update the specific field
        }));
      }
    }
    if (field === "price") {
      let formattedPrice = value.replace(/[^\d.]/g, "");
      const periodsCount = formattedPrice.split(".").length - 1;
      if (periodsCount > 1) {
        return;
      }
      const decimalIndex = formattedPrice.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPart = formattedPrice.substring(decimalIndex + 1);
        formattedPrice =
          formattedPrice.substring(0, decimalIndex + 1) +
          decimalPart.slice(0, 2);
      }
      if (formattedPrice.length > 7) {
        formattedPrice = formattedPrice.slice(0, 7);
        setTicketPriceError("Ticket price cannot exceed 7 characters");
      } else {
        const price = parseFloat(formattedPrice);
        if (price > 9999.99) {
          setTicketPriceError("Ticket price cannot exceed $9999.99");
          return;
        }
        setTicketPriceError("");
      }
      setTicket((prevTicket) => ({
        ...prevTicket,
        [field]: formattedPrice, // Dynamically update the specific field
      }));
    } else if (field === "quantityAvailable") {
      // Ensure the value is numeric and not more than 6 digits
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length > 6 || numericValue.startsWith("0")) {
        return;
      }
      setTicket((prevTicket) => ({
        ...prevTicket,
        [field]: numericValue, // Dynamically update the specific field
      }));
    } else if (field === "description") {
      setTicket((prevTicket) => ({
        ...prevTicket,
        [field]: value, // Dynamically update the specific field
      }));
    }
  };

  //if none of the fields are empty atake the user to step 2
  const handleSubmitForm = (e: any) => {
    setFormSubmitted(true);

    e.preventDefault();

    if (
      name &&
      status &&
      description &&
      category.length > 0 &&
      classification &&
      type &&
      feedImage &&
      pageImage &&
      venueImage &&
      eventTime &&
      eventDate &&
      (type !== "Ticketed" ||
        (ticket?.price &&
          ticket?.quantityAvailable &&
          ticket?.name &&
          ticket?.description) ||
        classification === "External") &&
      (type !== "Guest List" || guestLimit !== "" || maxRSVPs !== "") &&
      ((classification === "Hosted" && externalSiteUrl === "") ||
        (classification === "External" && externalSiteUrl !== ""))
    ) {
      setSteps(2);
    }
  };

  return (
    <section className="create-campaign-section">
      <div className="row">
        <div className="col-lg-6 col-md-9 adjust-height-fhbsh">
          <div className="form-section">
            <div className="form-header d-flex flex-row justify-content-between align-items-center">
              <h2>Create an Event</h2>
              <div className="toggle-tabs d-flex">
                <span
                  className={`toggle-tab ${
                    classification === "Hosted" ? "active" : ""
                  }`}
                  onClick={() => handleChange("Hosted", "classification")}
                >
                  Internal
                </span>
                <span
                  className={`toggle-tab ${
                    classification === "External" ? "active" : ""
                  }`}
                  onClick={() => handleChange("External", "classification")}
                >
                  External
                </span>
              </div>
            </div>
            <form onSubmit={handleSubmitForm}>
              <div className="row">
                <div className="d-flex flex-column col-6 mb-4">
                  <label className="form-label">Event Name *</label>
                  <input
                    value={name}
                    onChange={(e: any) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    name="name"
                    className="input-value-field"
                    placeholder="Name of the event"
                  />
                  {formSubmitted && !name && (
                    <div className="my-1 error-text-asd errorField">
                      *Event name required
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 status-wrapper mb-4 ">
                  <label className="form-label">Status *</label>
                  <SelectWrapper
                    options={statuses}
                    value={status}
                    setValue={(value: any) => handleChange(value, "status")}
                    placeholder="Please Select Status"
                  />
                  {formSubmitted && !status && (
                    <div className="my-1 error-text-asd errorField">
                      *Status required
                    </div>
                  )}
                </div>
                <div className="col-12 mb-4">
                  <label className="form-label">Event Description *</label>
                  <textarea
                    value={description}
                    name="description"
                    onChange={(e: any) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    id="campaignDesign"
                    className="text-area-2"
                    placeholder="Description of the event"
                    maxLength={999}
                  />
                  {formSubmitted && !description && (
                    <div className="my-1 error-text-asd errorField">
                      *Event description required
                    </div>
                  )}
                </div>
                {classification === "Hosted" && (
                  <div className="d-flex flex-column col-6 event-wrapper mb-4">
                    <label className="form-label">Type of Event *</label>
                    <SelectWrapper
                      options={types}
                      value={type}
                      setValue={(value: any) => handleChange(value, "type")}
                      placeholder="Choose event type"
                    />
                    {formSubmitted && !type && (
                      <div className="my-1 error-text-asd errorField">
                        *Type of event required
                      </div>
                    )}
                  </div>
                )}
                <div className="d-flex flex-column col-6 categories-wrapper mb-4">
                  <label className="form-label">Categories *</label>

                  <MultiSelectWrapper
                    options={categories.map((category: any) => category.value)}
                    placeholder="Select categories"
                    value={category}
                    setValue={(value: any) => handleChange(value, "category")}
                  />
                  {formSubmitted && category.length < 1 && (
                    <div className="my-1 error-text-asd errorField">
                      *Category required
                    </div>
                  )}
                </div>
                <div className="col-6 mb-4">
                  <label className="form-label">Event Date(s) *</label>
                  {classification === "Hosted" && type === "Exhibition" ? (
                    <>
                      <DatePicker
                        showIcon
                        icon={calenderIcon()}
                        toggleCalendarOnIconClick
                        showPopperArrow={true}
                        placeholderText="MM.DD.YY - MM.DD.YY"
                        dateFormat="MM-dd-yy"
                        minDate={new Date()}
                        selectsRange={true}
                        startDate={eventDate}
                        endDate={endDate}
                        onChange={(update: any) => {
                          if (update) {
                            handleChange(update[0], "eventDate");
                            handleChange(update[1], "endDate");

                            setDateError("");
                          } else {
                            setDateError("*Event date required");
                          }
                        }}
                      />
                      {!eventDate && !endDate && formSubmitted && (
                        <div className="my-1 error-text-asd errorField">
                          *Event Date required
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <DatePicker
                        showIcon
                        icon={calenderIcon()}
                        toggleCalendarOnIconClick
                        showPopperArrow={true}
                        placeholderText="MM.DD.YY"
                        dateFormat="MM-dd-yy"
                        minDate={new Date()}
                        selected={eventDate}
                        onChange={(date: any) => {
                          if (date) {
                            handleChange(date, "eventDate");

                            setDateError("");
                          } else {
                            setDateError("*Event date required");
                          }
                        }}
                      />
                      {!eventDate && formSubmitted && (
                        <div className="my-1 error-text-asd errorField">
                          *Event Date required
                        </div>
                      )}
                    </>
                  )}
                  {(!eventDate || !endDate) && (
                    <span className="error-color d-block">{dateError}</span>
                  )}
                </div>
                <div className="event-time-wrapper col-6 mb-4">
                  <label className="form-label">Event Time *</label>

                  <TimeRangePicker
                    handleChange={handleChange}
                    eventTime={eventTime}
                  />
                  <span className="time-icon">{timeIcon()}</span>
                  {formSubmitted && !eventTime && (
                    <div className="my-1 error-text-asd errorField">
                      *Event time required
                    </div>
                  )}
                  {/* {timeError && (
                    <span className="error-color">{timeError}</span>
                  )} */}

                  {validationError && eventTime && (
                    <span className="error-color">{validationError}</span>
                  )}
                </div>
                {classification === "Hosted" && type === "Ticketed" && (
                  <>
                    <div className="ticket-name col-6 mb-4">
                      <label className="form-label">Ticket Name *</label>
                      <input
                        value={ticket?.name}
                        onChange={(e) =>
                          handleTicketChange("name", e.target.value)
                        }
                        name="Ticket name"
                        className="input-value-field w-100"
                        placeholder="Please enter the ticket name"
                      />
                      {formSubmitted && !ticket.name && (
                        <div className="my-1 error-text-asd errorField">
                          Ticket name required
                        </div>
                      )}
                      {ticketNameError && (
                        <div className="my-1 error-text-asd errorField">
                          {ticketNameError}
                        </div>
                      )}
                    </div>
                    <div className="ticket-price col-6 mb-4">
                      <label className="form-label">Price of Ticket *</label>
                      <input
                        value={ticket.price === "" ? "" : `$${ticket.price}`}
                        onChange={(e) =>
                          handleTicketChange(
                            "price",
                            e.target.value.startsWith("$")
                              ? e.target.value.replace("$", "")
                              : e.target.value
                          )
                        }
                        name="Ticket Price"
                        className="input-value-field w-100"
                        placeholder="$00.00"
                      />
                      {formSubmitted && !ticket.price && (
                        <div className="my-1 error-text-asd errorField">
                          Ticket price required
                        </div>
                      )}
                      {ticketPriceError && (
                        <div className="my-1 error-text-asd errorField">
                          {ticketPriceError}
                        </div>
                      )}
                    </div>
                    <div className="ticket-qty col-6 mb-4">
                      <label className="form-label">Set Max Capacity *</label>
                      <input
                        value={ticket?.quantityAvailable}
                        onChange={(e) =>
                          handleTicketChange(
                            "quantityAvailable",
                            e.target.value
                          )
                        }
                        name="Quantity Available"
                        className="input-value-field w-100"
                        placeholder="0"
                      />
                      {formSubmitted && !ticket.quantityAvailable && (
                        <div className="my-1 error-text-asd errorField">
                          Max Capacity required
                        </div>
                      )}
                    </div>
                    <div className="col-12 mb-4">
                      <label className="form-label">Ticket Description *</label>
                      <textarea
                        value={ticket?.description}
                        onChange={(e) =>
                          handleTicketChange("description", e.target.value)
                        }
                        name="event name"
                        className="text-area-2"
                        placeholder="Description of this specific ticket"
                      />
                      {formSubmitted && !ticket.description && (
                        <div className="my-1 error-text-asd errorField">
                          Ticket description required
                        </div>
                      )}
                    </div>
                  </>
                )}
                {classification === "Hosted" && type === "Guest List" && (
                  <div className="ticket-qty col-6 mb-4">
                    <label className="form-label">Set Max Capacity *</label>
                    <input
                      value={maxRSVPs}
                      onChange={(e) => {
                        if (
                          /^(?:[0-9]|[1-9][0-9]{1,2}|1500)?$/.test(
                            e.target.value
                          )
                        ) {
                          handleChange(e.target.value, e.target.name);
                        }
                      }}
                      name="maxRSVPs"
                      className="input-value-field w-100"
                      placeholder="0"
                    />
                    {formSubmitted &&
                      maxRSVPs === "" &&
                      type === "Guest List" && (
                        <div className="my-1 error-text-asd errorField">
                          Max Capacity required
                        </div>
                      )}
                  </div>
                )}

                {classification === "Hosted" && type === "Guest List" && (
                  <div className="d-flex flex-column col-6 categories-wrapper mb-4">
                    <label className="form-label">Limit per Guest *</label>
                    <input
                      value={guestLimit}
                      onChange={(e: any) => {
                        if (/^[0-9]?$/.test(e.target.value)) {
                          handleChange(e.target.value, e.target.name);
                        }
                      }}
                      name="guestLimit"
                      className="input-value-field"
                      disabled={type !== "Guest List"}
                      placeholder="Guest Count"
                    />
                    {formSubmitted &&
                      guestLimit === "" &&
                      type === "Guest List" && (
                        <div className="my-1 error-text-asd errorField">
                          *Guest Count required
                        </div>
                      )}
                  </div>
                )}
                {classification === "External" && (
                  <div className="d-flex flex-column col-6 mb-4">
                    <label className="form-label">External Site URL *</label>
                    <input
                      value={
                        classification !== "External" ? "" : externalSiteUrl
                      }
                      onChange={(e) => {
                        const { name, value } = e.target;
                        handleChange(value, name);

                        const isValidUrl =
                          value.startsWith("http://") ||
                          value.startsWith("https://");
                        if (!isValidUrl) {
                          setUrlError("Please enter a valid URL");
                        } else {
                          setUrlError("");
                        }
                      }}
                      name="externalSiteUrl"
                      className="input-value-field"
                      placeholder="External Site Url"
                    />
                    {formSubmitted &&
                      classification === "External" &&
                      !externalSiteUrl && (
                        <div className="my-1 error-text-asd errorField">
                          *External site url required
                        </div>
                      )}
                    {urlError && (
                      <div className="my-1 error-text-asd errorField">
                        {urlError}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Commented code reflects to Jabir new Design */}
              {/* <div className="row">
                <div className="d-flex flex-column col-6 categories-wrapper">
                  <label className="form-label">Available to *</label>
                  <MultiSelectWrapper
                    options={event_available}
                    placeholder="Select event access for members"
                    value={availableTo}
                    setValue={(value: any) =>
                      handleChange(value, "availableTo")
                    }
                  />
                  {formSubmitted && availableTo?.length === 0 && (
                    <div className="my-1 error-text-asd errorField">
                      *Available to required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="d-flex flex-column col-6 event-wrapper">
                  <label className="form-label">
                    Classification of Event *
                  </label>
                  <SelectWrapper
                    options={classification_options}
                    value={classification}
                    setValue={(value: any) =>
                      handleChange(value, "classification")
                    }
                    placeholder="Choose event classification"
                  />
                  {formSubmitted && !classification && (
                    <div className="my-1 error-text-asd errorField">
                      *Classification of event required
                    </div>
                  )}
                </div>
              </div> */}

              <div className="row mb-4">
                <div className="col-6 d-flex flex-column justify-content-start mb-4">
                  <label className="form-label">Event Feed Image *</label>
                  <div className="img-desc-cni2 mb-2">
                    This will be shown as the main image on your offer feed in
                    the app.
                  </div>
                  <Image
                    required={true}
                    text="Event Feed Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 300, height: 360 }}
                    onImageSelected={() => console.log("Feed image uploaded")}
                    imageType="feed"
                    value={feedImage}
                    setValue={(feedImage) =>
                      handleChange(feedImage, "feedImage")
                    }
                  />
                </div>
                <div className="col-6 d-flex flex-column justify-content-start mb-4">
                  <label className="form-label">Event Page Image *</label>
                  <div className="img-desc-cni2 mb-2">
                    This will be shown as the primary image on the event page in
                    the app.
                    {/* Size 320px x 480px. */}
                  </div>
                  <Image
                    required={true}
                    text="Event Page Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 320, height: 480 }}
                    onImageSelected={() => console.log("Page image uploaded")}
                    imageType="page"
                    value={pageImage}
                    setValue={(pageImage) =>
                      handleChange(pageImage, "pageImage")
                    }
                  />
                </div>
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Venue Image</label>

                  <Image
                    required={true}
                    text="Event Venue Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 300, height: 360 }}
                    imageType="feed"
                    onImageSelected={() => console.log("Venue image uploaded")}
                    value={venueImage}
                    setValue={(venueImage) =>
                      handleChange(venueImage, "venueImage")
                    }
                  />
                </div>
              </div>

              {/* commented code reflects to jabir new design */}
              {/* <div className="row mb-4">
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Event Video</label>
                  <div className="img-desc-cni2 mb-2">
                    This will be shown as the primary video on the event page in
                    the app
                  </div>
                  <Video
                    required={true}
                    text="Event Video"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 300, height: 360 }}
                    imageType="event video"
                    onImageSelected={onImageSelected}
                    value={videoUrl}
                    setValue={(videoUrl) => handleChange(videoUrl, "videoUrl")}
                    type="video"
                  />
                </div>
                <div className="col-6 d-flex flex-column justify-content-start "></div>
              </div> */}

              {classification === "Hosted" && type === "Guest List" && (
                <div className="row mt-8 mb-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-2">
                    <div className="row">
                      <div className=" col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <label className="form-label">
                          Request Guest Approval?
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                        <div className="toggle-button-wrap f-campaign">
                          <Toggle
                            tabIndex={0}
                            id="cheese-status"
                            defaultChecked={requiredGuestApproval}
                            icons={false}
                            onChange={(e) =>
                              handleChange(
                                e.target.checked,
                                "requiredGuestApproval"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-2">
                    <div className="row">
                      <div className=" col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <label className="form-label">Event Audience</label>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                        <div className="toggle-button-wrap f-campaign">
                          <Toggle
                            tabIndex={0}
                            id="cheese-status"
                            defaultChecked={eventAudience}
                            icons={false}
                            onChange={(e) =>
                              handleChange(e.target.checked, "eventAudience")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row mt-8 mb-4">
                <div className="col-12 ">
                  <div className="next-button-wrapper">
                    <Button
                      title={"NEXT"}
                      type={"submit"}
                      backgroundColor="white"
                      color="black"
                      border="none"
                      borderRadius="6px"
                      padding="10px 48px"
                      fontSize="12px"
                      fontWeight="600"
                    />
                    <Modal
                      showModal={showModal}
                      handleClose={handleCloseModal}
                      handleExit={handleExit}
                      buttonTitle="EXIT"
                      title="Are you sure you want to exit?"
                      message="Are you sure you want to exit creating a new event? The information will not be saved."
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div style={{ position: "relative" }}>
            <div className="row stage-bar">
              <div className="col-4 step-1"></div>
              <div className="col-4"></div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
        <div className="form-image-right col-lg-6 col-md-3 p-0">
          <div className="image-section">
            <div className="overlay"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepOne;
