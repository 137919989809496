import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-data-grid/lib/styles.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { campaignTableHeaders } from "@constants/campaignTableHeaders";
import Loader from "../Spinner";
import { DownloadIcon } from "@constants/utils/svgs";
import { CampaignTableRow } from "@/@Interfaces/TableProps";
import { getCampaigns } from "@services/campaigns";
import { useSelector } from "react-redux";
import { options } from "@constants/options";
import { useNavigate } from "react-router-dom";
import TableFooter from "../TableFooter";
import EditCampaignModal from "../EditCampaign";
import { downloadCampaignAPI } from "@/services/campaignCsv";
import { getEventWithTicket } from "@/services/getEventTicket";
import { ICreateTicket } from "@/@Interfaces/CreateTicket";
import GrandModal from "@/modals/Modal";
import TruncateTextWithTooltip from "../Tooltip";
import showToast from "../cogo-toast";
import Modal from "../ExitModal";
import { deleteEvent } from "@/services/deleteEvent";

interface CampaignTableProps {
  // updateActiveTab: (tab: string) => void;
  setRsvpEventID: React.Dispatch<
    React.SetStateAction<{
      id: string;
      type: string;
      name: string;
    }>
  >;
}

const CampaignsTab: React.FC<CampaignTableProps> = ({ setRsvpEventID }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tableData, setTableData] = useState<CampaignTableRow[]>([]);
  const [editTableData, setEditTableData] = useState<ICreateTicket[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState<"asc" | "desc">("asc");
  const [sortOn, setSortOn] = useState<string>("name");
  const [deleteModal, setDeleteModal] = useState(false);
  const [eventId, setEventId] = useState<string | number>("");
  const [hoveredId, setHoveredId] = useState<number | null>(null);
  const [viewEvent, setViewEvent] = useState(false);
  const navigate = useNavigate();
  const latestDate = new Date();
  const formattedLatestDate = latestDate.toLocaleString();

  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const handleEditCampaignClick = async (rowData: CampaignTableRow) => {
    const response = await getEventWithTicket(rowData.id);
    setEditTableData(response.data);
    setIsEditModalOpen(true);
    setViewEvent(false);
  };

  const handleViewCampaignClick = async (rowData: CampaignTableRow) => {
    const response = await getEventWithTicket(rowData.id);
    setEditTableData(response.data);
    setIsEditModalOpen(true);
    setViewEvent(true);
  };

  const dropdownOption = useSelector(
    (state: any) => state.applicant.dropdownOption
  );

  const pageLimit = options.includes(dropdownOption) ? dropdownOption : "25";

  useEffect(() => {
    setCurrentPage(1);
  }, [dropdownOption]);

  useEffect(() => {
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;

    fetchData(1, pl, searchTerm, sortOn, sortBy);
  }, [dropdownOption, sortOn, sortBy]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;
    fetchData(pageNumber, pl, searchTerm);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  function convertDateFormat(inputDate: string | undefined): string {
    if (!inputDate) {
      return ""; // Handle the case where inputDate is undefined
    }

    const dateObject = new Date(inputDate);

    // Extracting individual date components
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is 0-indexed, so we add 1
    const year = dateObject.getFullYear(); // Keeping the full 4-digit year

    // Formatting the date components
    const formattedDate = `${month < 10 ? "0" : ""}${month}/${
      day < 10 ? "0" : ""
    }${day}/${year}`;

    return formattedDate;
  }

  const fetchData = async (
    pageNumber = 1,
    pageLimit: number,
    search = "",
    sortOn = "",
    sortBy = ""
  ) => {
    try {
      setShowSpinner(true);
      setTableData([]); //setting table data empty so that loader is shown only instead of showing at the bottom of the table where loader is not visible
      const response = await getCampaigns(
        pageNumber,
        pageLimit,
        search,
        sortOn,
        sortBy
      );
      setTableData(response.data.events);
      setTotalPages(response.data.totalPages);
      setTotalRecords(response.data.totalDocs);
    } catch (error: any) {
      setShowSpinner(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong. ", "error");
      }
    }
    setShowSpinner(false);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    fetchData(currentPage, pageLimit, searchTerm);
  };

  const handleAddCampaign = () => {
    navigate("/create-event");
  };

  const handleHeaderClick = (headerKey: string) => {
    setCurrentPage(1);
    if (sortOn === headerKey) {
      setSortBy((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy("desc");
      setSortOn(headerKey);
    }
  };

  const handleTypeClick = (type: string, searchID: string, name: string) => {
    if (type === "Guest List") {
      setRsvpEventID({ id: searchID, type, name });
      navigate("/home/attendees");
    } else if (type === "Ticketed") {
      setRsvpEventID({ id: searchID, type, name });
      navigate("/home/attendees");
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case "draft":
        return "#6F5BBF";
      case "live":
        return "#74DFAB";
      case "paused":
        return "#8F98A1";
      default:
        return "";
    }
  };

  const getStatusTextColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case "draft":
      case "paused":
        return "#FFFFFF";
      case "live":
        return "#121212";
      default:
        return "";
    }
  };

  const formatTicketPrice = (ticketPrice: string): string => {
    if (ticketPrice === "N/A") {
      return "-";
    }

    const parsedPrice = parseFloat(ticketPrice);

    if (isNaN(parsedPrice)) {
      return "-";
    }

    // Check if the parsed price has decimal places
    const hasDecimalPlaces = parsedPrice % 1 !== 0;

    let formattedPrice: string;

    if (hasDecimalPlaces) {
      formattedPrice = `$${parsedPrice.toFixed(2)}`; // Format to 2 decimal places if it's a floating point number
    } else {
      formattedPrice = `$${Math.floor(parsedPrice)}`; // No decimal places if it's an integer
    }

    if (ticketPrice.includes("-")) {
      const [min, max] = ticketPrice.split("-").map(
        (value) => parseFloat(value.trim()).toFixed(2) // Always format range values to 2 decimal places
      );
      return `$${min} - $${max}`;
    }

    return formattedPrice;
  };

  const handleSearchCampaign = (value: string) => {
    if (value.length >= 3 || value === "") {
      setCurrentPage(1);
      fetchData(1, pageLimit, value);
    }
  };

  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const downloadEventFile = async () => {
    try {
      setShowSpinner(true);
      const response = await downloadCampaignAPI();
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `PM_Events_${formattedLatestDate}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      showToast("Error in downloading file.", "error");
    } finally {
      setShowSpinner(false);
    }
  };

  const hoverBackgroundColor = (rowId: number) => {
    // Set the background color based on the hovered state and type
    return hoveredId && hoveredId === rowId ? "#313237" : "";
  };

  const getTruncatedText = (text: string) => {
    if (text.split(" ").length === 1) {
      return `${text.substring(0, 35)}${text.length > 35 ? "..." : ""}`;
    }
    return text;
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDeleteEvent = async () => {
    try {
      const response = await deleteEvent(eventId);
      showToast("Event deleted sucessfully!", "success");
      setDeleteModal(false);
      fetchData(1, pageLimit, searchTerm, sortOn, sortBy);
    } catch (error: any) {
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast(error.response.data.message, "error");
      }
      setDeleteModal(false);
    }
  };

  const handleDeleteClick = (rowData: CampaignTableRow) => {
    setDeleteModal(true);
    setEventId(rowData._id);
  };

  const handleCloseExitModal = () => {
    setDeleteModal(false);
  };

  return (
    <section className="table-section d-flex flex-column justify-content-between">
      <div className="table-header  d-flex flex-1 align-items-center flex-wrap justify-content-between">
        <h4 className="t-head">Events</h4>
        <div className="search-bar d-flex flex-wrap gap-3">
          <div>
            <form data-testid="search-form" onSubmit={handleSubmit2}>
              <div className="benefit-search-content">
                <FontAwesomeIcon
                  className="search-icon"
                  icon={faSearch}
                  style={{}}
                />
                <input
                  style={{ height: "40px" }}
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    const { value } = e.target;
                    handleSearchCampaign(value);
                  }}
                />
              </div>
              <button type="submit" style={{ display: "none" }}>
                Search
              </button>
            </form>
          </div>
          <div>
            <button
              className=""
              style={{
                width: "173px",
                height: "40px",
                borderRadius: "8px",
                border: "none",
                backgroundColor: "#ffffff",
              }}
              onClick={() => handleAddCampaign()}
            >
              <span
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#131313",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                ADD EVENT
              </span>
            </button>
          </div>
          <div className="download-icon" onClick={toggleDropdown}>
            {DownloadIcon()}
          </div>
        </div>
      </div>
      {showDropdown && (
        <div id="dropdown" className="csv-dropdown" ref={dropdownRef2}>
          <div onClick={downloadEventFile}>Export as .CSV</div>
          {/* <div className="pdf-container">Export as .PDF</div> commenting for future use*/}
        </div>
      )}
      <div className="table-container-hd73 flex-grow">
        <table
          id="table"
          className="table table-hover"
          style={{ backgroundColor: "#1A1A1C" }}
        >
          <thead className="t-header">
            <tr>
              {campaignTableHeaders.map((header: any) => (
                <th
                  className="t-columns"
                  key={header.key}
                  scope="col"
                  style={header.style}
                  onClick={() => handleHeaderClick(header.key)}
                >
                  {header.name}
                </th>
              ))}
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
            </tr>
          </thead>
          <tbody className="t-body">
            {!showSpinner && tableData.length === 0 && searchTerm.length > 0 ? (
              <td colSpan={campaignTableHeaders.length + 1}>
                <div className="no-records-found">
                  No records found.
                  <br />
                  <span
                    className="first-click"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={handleAddCampaign}
                  >
                    Click here
                  </span>
                  <span className="third-line"> to add your Event.</span>
                </div>
              </td>
            ) : !showSpinner &&
              tableData.length === 0 &&
              searchTerm.length === 0 ? (
              <td colSpan={campaignTableHeaders.length + 1}>
                <div className="no-records-found">
                  There are currently no Events
                  <br />
                  <span
                    className="first-click"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={handleAddCampaign}
                  >
                    Click here
                  </span>
                  <span className="third-line"> to add your first Event.</span>
                </div>
              </td>
            ) : (
              <>
                {" "}
                {tableData.map((rowData: CampaignTableRow, index) => {
                  const categories = rowData.category.toString().split(",");
                  return (
                    <tr key={index}>
                      {campaignTableHeaders.map((header: any) => (
                        <td
                          style={{
                            verticalAlign: "middle",
                          }}
                          key={header.key}
                        >
                          {header.key === "name" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "100px",
                                  width: "40px",
                                  height: "40px",
                                }}
                              >
                                <img
                                  style={{
                                    borderRadius: "12px",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    rowData.thumbnail
                                      ? rowData.thumbnail
                                      : rowData.feedImage
                                  }
                                  alt=""
                                />
                              </div>

                              <div
                                style={{
                                  width: "calc(100% - 50px)",
                                  paddingLeft: "10px",
                                  placeSelf: "center",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                }}
                                className="truncate-two-lines"
                              >
                                <TruncateTextWithTooltip text={rowData.name} />
                              </div>
                            </div>
                          ) : header.key === "description" ? (
                            <div className="truncate-two-lines t-description">
                              {getTruncatedText(rowData.description)}
                            </div>
                          ) : header.key === "category" ? (
                            <div>
                              <span className="table-category">
                                {/* {rowData?.category?rowData?.category?.map((category:string)=> `${category} `)} */}
                                {categories
                                  .slice(0, 3)
                                  .map(
                                    (category: any, index) =>
                                      `${category}${
                                        index < 2 &&
                                        index !== categories.length - 1
                                          ? ","
                                          : index === 2
                                          ? "..."
                                          : ""
                                      } `
                                  )}
                              </span>
                            </div>
                          ) : header.key === "type" ? (
                            <div
                              style={{
                                padding: "10px 0px 10px .50rem",
                                borderRadius: "8px",
                                fontWeight: "600",
                                transition: "background-color 0.3s ease",
                              }}
                            >
                              <span>{rowData.type}</span>
                            </div>
                          ) : header.key === "eventDate" ? (
                            <div>
                              {rowData.eventDate === rowData.endDate ? (
                                <div className="t-date">
                                  {rowData.eventDate
                                    ? convertDateFormat(rowData.eventDate)
                                    : ""}
                                </div>
                              ) : (
                                <div className="t-date">
                                  {rowData.eventDate
                                    ? convertDateFormat(rowData.eventDate)
                                    : ""}{" "}
                                  -{" "}
                                  {rowData.endDate
                                    ? convertDateFormat(rowData.endDate)
                                    : ""}
                                </div>
                              )}
                              <span>{rowData.eventTime}</span>
                            </div>
                          ) : header.key === "addressLine1" ? (
                            <div className="truncate-two-lines">
                              <a
                                className="click-address"
                                href={`https://www.google.com/maps/search/?api=1&query=${rowData.latitudes},${rowData.longitudes}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="t-address">
                                  {rowData.addressLine1}
                                </span>
                              </a>
                            </div>
                          ) : header.key === "ticketPrice" ? (
                            <div className="table-price">
                              {formatTicketPrice(rowData.ticketPrice)}
                            </div>
                          ) : header.key === "redemption" ? (
                            rowData.type === "Ticketed" ? (
                              <div
                                onMouseOver={() => setHoveredId(index)}
                                onMouseOut={() => setHoveredId(null)}
                                style={{
                                  padding: "10px 0px 10px .50rem",
                                  borderRadius: "8px",
                                  fontWeight: "600",
                                  backgroundColor: hoverBackgroundColor(
                                    index as number
                                  ),
                                  transition: "background-color 0.3s ease",
                                }}
                                className="table-redeemed"
                                onClick={() =>
                                  handleTypeClick(
                                    rowData.type,
                                    rowData.id as any,
                                    rowData.name
                                  )
                                }
                              >
                                {rowData.totalTickets.totalQuantityRedeemed} /{" "}
                                {rowData.totalTickets.totalQuantityAvailable}
                              </div>
                            ) : rowData.type === "Guest List" ? (
                              <div
                                onMouseOver={() => setHoveredId(index)}
                                onMouseOut={() => setHoveredId(null)}
                                style={{
                                  padding: "10px 0px 10px .50rem",
                                  borderRadius: "8px",
                                  fontWeight: "600",
                                  backgroundColor: hoverBackgroundColor(
                                    index as number
                                  ),
                                  transition: "background-color 0.3s ease",
                                }}
                                className="table-redeemed"
                                onClick={() =>
                                  handleTypeClick(
                                    rowData.type,
                                    rowData.id as any,
                                    rowData.name
                                  )
                                }
                              >
                                {rowData?.redeemedRsvps} / {rowData?.maxRSVPs}
                              </div>
                            ) : (
                              <div
                                style={{
                                  padding: "10px 0px 10px .50rem",
                                  borderRadius: "8px",
                                  fontWeight: "600",
                                }}
                                className="table-redeemed"
                              >
                                -
                              </div>
                            )
                          ) : header.key === "status" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "left",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    rowData.status
                                  ),
                                  borderRadius: "8px",
                                  padding: "5px 8px",
                                  fontWeight: 700,
                                  color: getStatusTextColor(rowData.status),
                                  marginBottom: 0,
                                }}
                              >
                                {rowData.status.toUpperCase()}
                              </div>
                            </div>
                          ) : (
                            rowData[header.key as keyof typeof rowData]
                          )}
                        </td>
                      ))}
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <button
                          className="edit-button "
                          onClick={() => handleViewCampaignClick(rowData)}
                        >
                          View
                        </button>
                      </td>
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <button
                          className="edit-button "
                          role="button"
                          name="Edit"
                          onClick={() => handleEditCampaignClick(rowData)}
                        >
                          Edit
                        </button>
                      </td>
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <button
                          className="edit-button "
                          role="button"
                          name="delete"
                          onClick={() => handleDeleteClick(rowData)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
        {showSpinner && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>
      <TableFooter
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        handlePrev={handlePrev}
        handleNext={handleNext}
        totalPages={totalPages}
        currentPage={currentPage}
      />
      {isEditModalOpen && (
        <GrandModal isOpen={isEditModalOpen} onClose={handleCloseModal}>
          <EditCampaignModal
            handleClose={handleCloseModal}
            editEventData={editTableData}
            viewEvent={viewEvent}
          />
        </GrandModal>
      )}

      <section>
        <div>
          {deleteModal === true && (
            <Modal
              showModal={deleteModal}
              handleClose={handleCloseExitModal}
              buttonTitle="DELETE"
              handleExit={handleDeleteEvent}
              title="Are you sure you want to delete this event?"
              message="This will delete the event permanently. This process cannot be undone."
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default CampaignsTab;
