import { patch } from "./api";
import URLS from "../constants/api";

export const changeRsvpStatus = async (
  id: string,
  status: string,
  eventId?: string
) => {
  const response = patch(`${URLS.CHANGE_RSVP_STATUS}/${id}`, {
    status,
    eventId,
  });
  return response;
};
