import { event_available } from "@/constants/eventAvailable";
import { DashboardIcon } from "@/constants/utils/svgs";
import { createTicket } from "@/services/ticket";
import { createEventWithImage } from "@/services/updateEventImages";
import { uploadFile } from "@/services/uploadFiles";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "react-toggle/style.css";
import showToast from "../cogo-toast";
import StepsTwo from "../EventDetails";
import Sidebar from "../Sidebar";
import StepsThree from "../TicketDetails";
import StepOne from "./stepone";
import "./style.css";
import { IStepOneFormDataType } from "@/@Interfaces/EventStepOneProps";
import { IStepTwoFormDataTypes } from "@/@Interfaces/EventStepTwoProps";
import moment from "moment";
import { TicketFormData } from "@/@Interfaces/CampaignProps";

const CreateCampaign: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<number>(1);

  const [validationError, setValidationError] = useState<string | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);

  const [stepOneFormData, setstepOneFormData] = useState<IStepOneFormDataType>({
    name: "",
    category: [],
    description: "",
    type: "Ticketed",
    status: "",
    // availableTo: [],
    classification: "Hosted",
    guestLimit: 0,
    externalSiteUrl: "",
    requiredGuestApproval: false,
    feedImage: null,
    pageImage: null,
    eventAudience: false,
    // videoUrl: null,
    eventDate: "",
    endDate: "",
    eventTime: "",
    venueImage: null,
    maxRSVPs: 0,
  });

  const [stepTwoFormData, setstepTwoFormData] = useState<IStepTwoFormDataTypes>(
    {
      timezone: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      longitudes: "",
      latitudes: "",
      postalCode: "",
      // website: "",
      // phoneNo: "",
    }
  );

  const [rawAddress, setRawAddress] = useState();
  // const [ticketName, setTicketName] = useState<string>("");
  // const [ticketPrice, setTicketPrice] = useState();
  // const [ticketDesc, setTicketDesc] = useState<string>("");
  // const [availableTo, setAvailableTo] = useState<string[]>([]);
  // const [quantityAvailable, setQuantityAvailable] = useState();
  const [ticket, setTicket] = useState<TicketFormData>({
    // availableTo: "",
    name: "",
    price: "",
    description: "",
    quantityAvailable: "",
  });

  const navigate = useNavigate();

  //create an event with tickets
  const handleSubmitCampaign = async () => {
    if (stepOneFormData.eventDate && !dateError) {
      try {
        setLoading(true);

        //creating a payload
        const eventData = {
          ///////// step one form data
          requiredGuestApproval:
            stepOneFormData.classification === "Hosted"
              ? stepOneFormData.requiredGuestApproval
              : false,
          name: stepOneFormData.name,
          ...(stepOneFormData.guestLimit && {
            guestLimit: stepOneFormData.guestLimit,
          }),
          ...(stepOneFormData.classification === "Hosted" &&
            stepOneFormData.type === "Guest List" && {
              maxRSVPs: stepOneFormData.maxRSVPs,
            }),
          // availableTo: stepOneFormData.availableTo,
          classification: stepOneFormData.classification,
          ...(stepOneFormData.classification === "External" &&
            stepOneFormData.externalSiteUrl !== "" && {
              externalSiteUrl: stepOneFormData.externalSiteUrl,
            }),
          description: stepOneFormData.description,
          status: stepOneFormData.status,
          category: stepOneFormData.category,
          ...(stepOneFormData.classification === "Hosted" && {
            type: stepOneFormData.type,
          }),
          eventAudience:
            stepOneFormData.classification === "Hosted"
              ? stepOneFormData.eventAudience
              : false,

          /////// step two form data
          // phoneNo: stepTwoFormData.phoneNo,
          // website: stepTwoFormData.website,
          eventDate: moment(stepOneFormData.eventDate).format("MM-DD-YY"),

          endDate: stepOneFormData.endDate
            ? moment(stepOneFormData?.endDate).format("MM-DD-YY")
            : moment(stepOneFormData?.eventDate).format("MM-DD-YY"),

          timezone: stepTwoFormData.timezone.label,
          eventTime: stepOneFormData.eventTime,
          ...(stepTwoFormData.addressLine1 !== "" && {
            addressLine1: stepTwoFormData.addressLine1,
          }),
          ...(stepTwoFormData.addressLine2 !== "" && {
            addressLine2: stepTwoFormData.addressLine2,
          }),
          ...(stepTwoFormData.city !== "" && {
            city: stepTwoFormData.city.trim(),
          }),
          ...(stepTwoFormData.country !== "" && {
            country: stepTwoFormData.country.trim(),
          }),
          ...(stepTwoFormData.postalCode !== "" && {
            postalCode: stepTwoFormData.postalCode,
          }),
          ...(stepTwoFormData.latitudes !== null && {
            latitudes: stepTwoFormData.latitudes,
          }),
          ...(stepTwoFormData.longitudes !== null && {
            longitudes: stepTwoFormData.longitudes,
          }),
        };

        //create a ticket payload
        const ticketData = {
          name: ticket?.name,
          description: ticket.description,
          // availableTo: ticket.availableTo,
          price: parseFloat(ticket.price) || 0,
          quantityAvailable: parseInt(ticket.quantityAvailable) || 0,
        };

        const combinedData = {
          event: eventData,
          ...(stepOneFormData.classification === "Hosted" &&
            ticketData.price !== 0 &&
            ticketData.quantityAvailable !== 0 && {
              tickets: [ticketData],
            }),
        };

        const apiResponse = await createTicket(combinedData);
        const eventId = apiResponse.data.eventId;
        const uploadFiles = {
          type: "Events",
          id: eventId,
          feedImage: stepOneFormData.feedImage,
          pageImage: stepOneFormData.pageImage,
          // videoUrl: stepOneFormData.videoUrl,
          venueImage: stepOneFormData.venueImage,
        };
        //upload images to cloud front
        const upload = await uploadFile(uploadFiles);
        //update event with images url
        await createEventWithImage(
          apiResponse.data.eventId,
          upload[0],
          upload[1],
          upload[0],
          upload[2]
          // uploadFiles.videoUrl ? upload[2] : null,
          // uploadFiles.venueImage && uploadFiles.videoUrl
          // ? upload[3]
          // : uploadFiles.venueImage
          // ? upload[2]
          // : null
        );
        setLoading(false);
        navigate("/home/events");
        showToast("Event created successfully!", "success");
      } catch (err: any) {
        setLoading(false);
        if (err.message === "Network Error") {
          showToast(
            "It seems you're offline. Please check your connection and try again.",
            "error"
          );
        } else {
          showToast("Something went wrong. Please try again.", "error");
        }
      }
    }
  };

  return (
    <div>
      {steps === 1 && (
        <div className="d-flex gap-2" style={{ height: "100vh" }}>
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepOne
            setSteps={setSteps}
            setstepOneFormData={setstepOneFormData}
            stepOneFormData={stepOneFormData}
            setDateError={setDateError}
            dateError={dateError}
            validationError={validationError}
            ticket={ticket}
            setTicket={setTicket}
          />
        </div>
      )}

      {steps === 2 && (
        <div className="d-flex gap-2" style={{ height: "100vh" }}>
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepsTwo
            setstepTwoFormData={setstepTwoFormData}
            stepTwoFormData={stepTwoFormData}
            eventTypeOption={stepOneFormData.type}
            loading={loading}
            setSteps={setSteps}
            rawAddress={rawAddress}
            setRawAddress={setRawAddress}
            handleSubmitCampaign={handleSubmitCampaign}
            setValidationError={setValidationError}
          />
        </div>
      )}

      {/* {steps > 2 && (
        <div className="d-flex gap-2" style={{ height: "100vh" }}>
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
           <StepsThree
            loading={loading}
            setLoading={setLoading}
            steps={steps}
            setSteps={setSteps}
            ticketName={ticketName}
            setTicketName={setTicketName}
            ticketPrice={ticketPrice}
            setTicketPrice={setTicketPrice}
            ticketDesc={ticketDesc}
            setTicketDesc={setTicketDesc}
            availableTo={availableTo}
            setAvailableTo={setAvailableTo}
            quantityAvailable={quantityAvailable}
            setQuantityAvailable={setQuantityAvailable}
            tickets={tickets}
            setTickets={setTickets}
            handleSubmitCampaign={handleSubmitCampaign}
            ticketAvailableOptions={ticketAvailableOptions}
            setTicketAvailableOptions={setTicketAvailableOptions}
            eventAvailableTo={stepOneFormData.availableTo}
          /> 
        </div>
      )} */}
    </div>
  );
};

export default CreateCampaign;
