import React, { useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavigationPaths } from "@/constants/paths";
import { StylesConfig } from "react-select";
import { CampaignIcon, ExitIcon, searchIcon } from "@/constants/utils/svgs";
import Modal from "../ExitModal";
import { countries } from "@/constants/countries";
import Button from "../Button";
import SelectWrapper from "../selectWrapper";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { updateLocation } from "@/redux/slices/locationSlice";
import { BusinessFormData } from "@/@Interfaces/BusinessStepTwoProps";
import { StepTwoProps } from "@/@Interfaces/BusinessStepTwoProps";

const StepTwoBusiness: React.FC<StepTwoProps> = ({
  setSteps,
  locationInfo,
  setLocationInfo,
  setSelectedDate,
  rawAddress,
  setRawAddress,
  instagramHandler,
  setInstagramHandler,
  linkedInURL,
  setLinkedInURL,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dateError, setDateError] = useState<string | null>(null);
  const [postError, setPostError] = useState<string>("");
  const [linkedinError, setLinkedinError] = useState<string>("");
  const [value, setValue] = useState<any | null>(null);
  const API_KEY = process.env.REACT_APP_BASE_API_KEY;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string>("");

  const {
    formState: { errors },
    setValue: formValues,
  } = useForm<BusinessFormData>({
    mode: "onChange",
  });

  const country_country = useSelector(
    (state: any) => state.applicant.dropdownOption
  );
  const city = useSelector((state: any) => state.location.city);
  const addressLine1 = useSelector((state: any) => state.location.addressLine1);
  const addressLine2 = useSelector((state: any) => state.location.addressLine2);
  const postalCode = useSelector((state: any) => state.location.postalCode);
  const latitudes_1 = useSelector((state: any) => state.location.latitudes);
  const longitudes_1 = useSelector((state: any) => state.location.longitudes);
  const country = useSelector((state: any) => state.location.country);


  //set value of country from dropdown menu
  useEffect(() => {
    setSelectedCountries(locationInfo?.country);
  }, [locationInfo]);

  useEffect(() => {
    if (city) {
      formValues("addressLine1", addressLine1);
      formValues("addressLine2", addressLine2);
      formValues("city", city);
      formValues("postalCode", postalCode);
      formValues("country", country);
      formValues("coordinates", `${longitudes_1},${latitudes_1}`);
    }
  }, []);

  useEffect(() => {
    if (value && value.label) {
      setRawAddress(value);
      //extract country, address and city from location value
      const formattedAddress = value.label;
      const addressParts = formattedAddress.split(", ");
      const country = addressParts.pop() || "";
      const city = addressParts.pop() || "";
      const address = addressParts.join(", ");

      geocodeByAddress(address)
        .then((results) => {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();

          setLocationInfo((prevState) => ({
            ...prevState,
            address: address,
            latitudes: lat,
            longitudes: lng,
          }));
        })
        .catch((error) => console.error(error));
      setLocationInfo((prevState) => ({
        ...prevState,
        country: country,
        city: city,
        addressLine1: address,
      }));
    }
  }, [value]);

  useEffect(() => {
    if (locationInfo.addressLine1) {
      formValues("addressLine1", locationInfo.addressLine1);
      formValues("city", locationInfo.city);
      formValues(
        "coordinates",
        `${locationInfo.longitudes},${locationInfo.latitudes}`
      );
    }
  }, [locationInfo]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.BUSINESSES);
  };

  const onSubmit: SubmitHandler<BusinessFormData> = async (
    data: BusinessFormData
  ) => {
    dispatch(
      updateLocation({
        ...data,
        address: value,
        country: country_country,
      })
    );
    setSteps(3);
  };

  const handleNext = () => {
    setFormSubmitted(true);
    return;
  };
  const handleBack = () => {
    setSteps(1);
  };

  const customStyles: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor:  "#8E929A",
      boxShadow: state.isFocused ==true? "0 0 0 1px #BBA383" : "none",
      // opacity: 1,
      borderRadius: "6px",
      padding: "5px 0px",
      height: "48px",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      color: state.isSelected ? "white" : "inherit",
      fontSize: "12px",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "25px",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "8px !important",
      backgroundColor: "#1f1f22",
      color: "white",
      zIndex: 9999,
      fontSize: "12px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      // display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginLeft: "25px",
    }),
  };

  const handleLocationChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "postalCode" && value.length > 10) {
      setPostError("Post or ZIP code cannot exceed 10 characters");
    } else {
      setPostError("");
      setLocationInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
 
  //if none of the fields are empty go to step 3
  const handleStepTwoSubmit = (e: any) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (
      locationInfo?.addressLine1 &&
      locationInfo?.city &&
      locationInfo.postalCode &&
      linkedinError === ""
    ) {
      setSteps(3);
    }
  };
  return (
    <section className="create-campaign-section w-100">
      <div className="row">
        <div className="col-lg-6 col-md-9 p-0 adjust-height-fhbsh">
          <div className="business-form-section">
            <div className="event-form-header">
              <h2>Add a business</h2>
              <p>Step 2 of 4</p>
            </div>
            <form className="form-area" onSubmit={handleStepTwoSubmit}>
              <div className="row mb-4">
                <div className="d-flex flex-column col-6 ">
                  <div className="form-label input-label-f3s mb-3 text-truncate">
                    Instagram Handle *
                  </div>
                  <input
                    type="text"
                    value={instagramHandler}
                    onChange={(e) => setInstagramHandler(e.target.value)}
                    name="instagramHandler"
                    className="input-value-field"
                    placeholder="Business Instagram handle"
                    maxLength={40}
                  />
                  {formSubmitted && !instagramHandler && (
                    <div className="my-1 error-text-asd errorField">
                      *Instagram handle required
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 ">
                  <div className="form-label input-label-f3s mb-3 text-truncate">
                    LinkedIn URL *
                  </div>
                  <input
                    type="text"
                    value={linkedInURL}
                    onChange={(e) => {
                      const url = e.target.value;
                      setLinkedInURL(e.target.value);
                      if (
                        !url ||
                        !/^https:\/\/(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9-._/]+$/.test(
                          url
                        )
                      ) {
                        setLinkedinError("Please enter a valid LinkedIn URL");
                      } else {
                        setLinkedinError("");
                      }
                    }}
                    name="linkedInURL"
                    className="input-value-field"
                    placeholder="Business LinkedIn URL"
                    maxLength={200}
                  />
                  {formSubmitted && !linkedInURL && (
                    <div className="my-1 error-text-asd errorField">
                      *LinkedIn URL required
                    </div>
                  )}
                  {linkedInURL && linkedinError && (
                    <div className="my-1 error-text-asd errorField">
                      {linkedinError}
                    </div>
                  )}
                </div>
              </div>
              <div className="event-address position-relative row ">
                <label className="form-label input-label-f3s">
                  Business Address *
                </label>
                <span className="search-icon-illusion">{searchIcon()}</span>
                <GooglePlacesAutocomplete
                  apiKey={API_KEY}
                  selectProps={{
                    placeholder: "Search Address",
                    defaultValue: rawAddress,
                    onChange: (values) => {
                      setValue(values);
                    },
                    styles: customStyles,
                    isClearable: true,
                  }}
                />
              </div>
              <div className="event-form-wrapper mt-4 row mb-4">
                <div className="address-line-1 col-6">
                  <input
                    type="text"
                    value={locationInfo.addressLine1}
                    onChange={handleLocationChange}
                    name="addressLine1"
                    className="input-value-field w-100"
                    placeholder="Address line one"
                    maxLength={300}
                  />
                  {formSubmitted && !locationInfo?.addressLine1 && (
                    <div className="my-1 error-text-asd errorField">
                      Address line one required
                    </div>
                  )}
                </div>
                <div className="address-line-2 col-6">
                  <input
                    type="text"
                    value={locationInfo.addressLine2}
                    onChange={handleLocationChange}
                    name="addressLine2"
                    className="input-value-field w-100"
                    placeholder="Address line two"
                    maxLength={300}
                  />
                </div>
              </div>

              <div className="event-form-wrapper row mb-4">
                <div className="city col-6">
                  <input
                    type="text"
                    value={locationInfo.city}
                    onChange={handleLocationChange}
                    name="city"
                    className="input-value-field w-100"
                    placeholder="City"
                    maxLength={50}
                  />
                  {formSubmitted && !locationInfo?.city && (
                    <div className="my-1 error-text-asd errorField">
                      City required
                    </div>
                  )}
                </div>
                <div className="country col-6">
                  <SelectWrapper
                    options={countries}
                    value={selectedCountries}
                    setValue={setSelectedCountries}
                    placeholder="Country"
                  />
                  {formSubmitted && !selectedCountries && (
                    <div className="my-1 error-text-asd errorField">
                      Country required
                    </div>
                  )}
                </div>
              </div>
              <div className="event-form-wrapper row">
                <div className="post-code col-6">
                  <input
                    type="text"
                    value={locationInfo.postalCode}
                    onChange={handleLocationChange}
                    name="postalCode"
                    className="input-value-field w-100"
                    placeholder="Post or ZIP Code"
                    maxLength={10}
                  />
                  {formSubmitted && !locationInfo?.postalCode && (
                    <div className="my-1 error-text-asd errorField">
                      Postal code required
                    </div>
                  )}
                  <div className="my-1 error-text-asd errorField">
                    {postError}
                  </div>
                </div>
                <div className="col-6">
                  <input
                    style={{ display: "none" }}
                    onChange={handleLocationChange}
                    type="text"
                    id="coordinates"
                    className="input-value-field w-100"
                    placeholder="Longitude, Latitude"
                    value={`${locationInfo.longitudes}, ${locationInfo.latitudes}`}
                  />
                </div>
              </div>
              <div className="button-wrapper">
                <div className="back-btn-area">
                  <Button
                    title={"BACK"}
                    type={"button"}
                    backgroundColor="#1A1A1C"
                    color="white"
                    border="1px solid white"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleBack}
                  />
                </div>
                <div className="next-btn-area">
                  <Button
                    title={"NEXT"}
                    type={"submit"}
                    backgroundColor="white"
                    color="black"
                    border="none"
                    borderRadius="6px"
                    padding="10px 48px 11px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleNext}
                  />
                  {/* exit modal */}
                  <Modal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    handleExit={handleExit}
                    buttonTitle="EXIT"
                    title="Are you sure you want to exit?"
                    message="Are you sure you want to exit creating a new business? The information will not be saved."
                  />
                </div>
              </div>
            </form>
          </div>
          {/* progress bar */}
          <div style={{ position: "relative" }}>
            <div className="row stage-bar">
              <div className="col-3 step-1-event"></div>
              <div className="col-3 step-1"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        {/* exit modal icon */}
        <div className=" col-lg-6 col-md-3 p-0 form-image-right">
          <div className="event-image">
            <div className="overlay-2"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepTwoBusiness;
