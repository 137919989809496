import React, { useState, useEffect } from "react";
import Button from "../Button";
import { format } from "date-fns";
import Toggle from "react-toggle";
import Loader from "../Spinner";
import { benefit_types } from "@/constants/benefitTypes";
import SelectWrapper from "../selectWrapper";
import { statusDropdown } from "@/constants/status";
import DatePicker from "react-datepicker";
import { categories } from "@/constants/categories";
import EventImage from "../EventImage";
import showToast from "../cogo-toast";
import "./style.css";
import { searchIcon } from "@/constants/utils/svgs";
import { StylesConfig } from "react-select";
import { countries } from "@/constants/countries";
import { EditBenefitModalProps } from "@/@Interfaces/ModalProps";
import { calenderIcon } from "@/constants/utils/svgs";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { useForm } from "react-hook-form";
import { uploadFile } from "@/services/uploadFiles";
import { updateEBenefit } from "@/services/editBenefit";
import { onlineOptions } from "@/constants/onlineOptions";
import { EditFormData } from "@/@Interfaces/EditBenefitForm";
import { event_available } from "@/constants/eventAvailable";
import MultiSelectWrapper from "../MultiSelect";

const EditBenefitModal: React.FC<EditBenefitModalProps> = ({
  handleClose,
  editBenefitData,
  viewBenefit,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [feedImageSelected, setFeedImageSelected] = useState(false);
  const [pageImageSelected, setPageImageSelected] = useState(false);
  const [pageImageS3, setPageImageS3] = useState("");
  const [feedImageS3, setFeedImageS3] = useState("");
  const [dateError, setDateError] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState("12:00-14:00");
  const [validationError, setValidationError] = useState<string | null>(null);
  const [urlError, setUrlError] = useState("");
  const [postError, setPostError] = useState("");
  const [rawAddress, setRawAddress] = useState(editBenefitData.addressLine1);
  const [value, setValue] = useState<any | null>(null);
  const [selectedCountries, setSelectedCountries] = useState<string>("");
  const [name, setName] = useState<string>(editBenefitData.benefit.name);
  const [status, setStatus] = useState<string>(editBenefitData.benefit.status);
  const [type, setType] = useState<string>(editBenefitData.benefit.type);
  const [benefitAvailableTo, setBenefitAvailableTo] =useState(editBenefitData.benefit.availableTo);
  const [editButton, setEditButton] = useState(false);
  const [isToggled, setIsToggled] = useState<boolean>(
    editBenefitData.benefit.displayOnAccess
  );
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState<string>(
    editBenefitData.benefit.description
  );
  const [isOnline, setIsOnline] = useState(
    editBenefitData.benefit.online ? "Yes" : "No"
  );
  const [category, setCategory] = useState<string>(
    editBenefitData.benefit.category
  );
  const [country, setCountry] = useState<string>(
    editBenefitData.benefit.country
  );
  const [city, setCity] = useState<string>(editBenefitData.benefit.city);
  const [postalCode, setPostalCode] = useState(
    editBenefitData.benefit.postalCode
  );
  const [benefitUrl, setBenefiturl] = useState(
    editBenefitData.benefit.benefitUrl
  );
  const [addressLine1, setAddressLine1] = useState<string>(
    editBenefitData.benefit.addressLine1
  );
  const [addressLine2, setAddressLine2] = useState<string>(
    editBenefitData.benefit.addressLine2
  );
  const [selectedPageImage, setSelectedPageImage] = useState(
    editBenefitData.benefit.pageImage
  );
  const [selectedFeedImage, setSelectedFeedImage] = useState(
    editBenefitData.benefit.feedImage
  );

  const [locationInfo, setLocationInfo] = useState<EditFormData>({
    startDate: null,
    startTime: "",
    country: country,
    benefitUrl: editBenefitData.benefit.benefitUrl,
    city: city,
    address: addressLine1,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    postalCode: postalCode,
    latitudes: editBenefitData.benefit.latitudes,
    longitudes: editBenefitData.benefit.longitudes,
    coordinates: "",
  });
  const dateString = editBenefitData.benefit.startDate;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const initialStartDate = new Date(editBenefitData.benefit.startDate);
  const initialEndDate = new Date(editBenefitData.benefit.endDate);

  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate);

  const API_KEY = process.env.REACT_APP_BASE_API_KEY;

  useEffect(() => {
    //upload images to cloud front
    const uploadFiles = async () => {
      setEditButton(true)
      if (
        typeof selectedPageImage !== "string" &&
        typeof selectedFeedImage !== "string"
      ) {
        const uploadFiles = {
          type: "Benefits",
          id: editBenefitData._id,
          pageImage: selectedPageImage,
          feedImage: selectedFeedImage,
        };

        try {
          const upload = await uploadFile(uploadFiles);
          setFeedImageS3(upload[0]);
          setPageImageS3(upload[1]);
          setEditButton(false)
        } catch (error: any) {
          if (error.message === "Network Error") {
            showToast(
              "It seems you're offline. Please check your connection and try again.",
              "error"
            );
          } else {
            showToast("Error in uploading files. Please upload again.", "error");
          }
          setEditButton(false)
        }
      }
    };

    uploadFiles();
  }, [selectedPageImage][selectedFeedImage]);

  const handleToggle = () => {
    setIsToggled((prevState: any) => !prevState);
  };

  //extract filename from image url
  function processLogoUrl(logoUrl: string): string {
    const lastSlashIndex = logoUrl.lastIndexOf("/");
    const processedUrl =
      lastSlashIndex !== -1 ? logoUrl.substring(lastSlashIndex + 1) : logoUrl;
    const finalProcessedUrl = processedUrl.replace(/%20/g, " ");
    return finalProcessedUrl;
  }

  const onImageSelected = async (imageType: string, file: File) => {
    if (imageType === "feed") {
      setFeedImageSelected(true);
    } else if (imageType === "page") {
      setPageImageSelected(true);
    }
  };

  const customStyles: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor:  "#8E929A",
      boxShadow: state.isFocused ==true ? "0 0 0 1px #BBA383" : "none",
      borderRadius: "6px",
      height: "48px",
      padding: "5px 0px",
      width: "100%",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      fontSize: "12px",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "25px",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1f1f22",
      color: "white",
      fontSize: "12px",
      zIndex: 9999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      // display: "none",
    }),
  };

  const handleLocationChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "postalCode") {
      if (value !== "" && !/^\d+$/.test(value)) {
        setPostError("Postal code must be an integer");
        return;
      } else {
        setPostError("");
      }
    }

    setLocationInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue: formValues,
  } = useForm<EditFormData>({
    mode: "onChange",
  });

  //extract city, country, address etc from location's value
  useEffect(() => {
    if (value && value.label) {
      setRawAddress(value);
      const formattedAddress = value.label;
      const addressParts = formattedAddress.split(", ");
      const country = addressParts.pop() || "";
      const city = addressParts.pop() || "";
      const address = addressParts.join(", ");

      geocodeByAddress(address)
        .then((results) => {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          setLocationInfo((prevState) => ({
            ...prevState,
            address: address,
            latitudes: lat,
            longitudes: lng,
          }));
        })
        .catch((error) => console.error(error));
      setLocationInfo((prevState) => ({
        ...prevState,
        country: country,
        city: city,
        addressLine1: address,
      }));
    }
  }, [value]);

  //set the country value from dropdown
  useEffect(() => {
    setSelectedCountries(locationInfo?.country);
  }, [locationInfo]);

  //update the benefit
  const handleUpdateBenefit = async () => {
    setLoading(true);
    setFormSubmitted(true);
    let formattedStartDate, formattedEndDate;
    if (selectedStartDate) {
      formattedStartDate = format(selectedStartDate, "MM-dd-yy");
    }
    if (selectedEndDate=== null){
      formattedEndDate = formattedStartDate;
    }
    if (selectedEndDate=== null){
      setSelectedEndDate(selectedStartDate)
    }
    if (selectedEndDate) {
      formattedEndDate = format(selectedEndDate, "MM-dd-yy");
    }
    // creating a payload
    const benefitData =
      isOnline === "Yes"
        ? {
            displayOnAccess: isToggled,
            name: name,
            status: status,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            description: description,
            category: category,
            type: type,
            benefitUrl: locationInfo.benefitUrl,
            online: true,
            availableTo: benefitAvailableTo,
            feedImage: feedImageS3 ? feedImageS3 : selectedFeedImage,
            pageImage: pageImageS3 ? pageImageS3 : selectedPageImage,
          }
        : {
            displayOnAccess: isToggled,
            name: name,
            description: description,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            online: false,
            availableTo: benefitAvailableTo,
            addressLine1: locationInfo?.addressLine1 || "",
            addressLine2: locationInfo?.addressLine2 || "",
            city: locationInfo?.city || "",
            country: locationInfo?.country || "",
            postalCode: locationInfo?.postalCode || "",
            latitudes: locationInfo?.latitudes || null,
            longitudes: locationInfo?.longitudes || null,
            status: status,
            category: category,
            type: type,
            feedImage: feedImageS3 ? feedImageS3 : selectedFeedImage,
            pageImage: pageImageS3 ? pageImageS3 : selectedPageImage,
          };

    const isEventDataValid = Object.entries(benefitData).every(
      ([key, value]) => {
        if (key === "addressLine2") {
          return true;
        }
        return value !== null && value !== "";
      }
    );
    //check if form has empty values
    if (isEventDataValid && urlError === "" && benefitAvailableTo.length !== 0) {
      try {
        const combinedData = {
          benefit: benefitData,
        };
        const response = await updateEBenefit(
          editBenefitData.benefit._id,
          combinedData
        );
        setLoading(false);
        handleClose();
        showToast(`Benefit Updated Successfully!`, "success");
      } catch (error: any) {
        setLoading(false);
        if (error.message === "Network Error") {
          showToast(
            "It seems you're offline. Please check your connection and try again.",
            "error"
          );
        } else {
          showToast(`Error Updating event ${error}`, "error");
        }
      }
    } else {
      showToast(
        "Benefit data contains null or empty values. Cannot update.",
        "error"
      );
      setLoading(false);
    }
  };

  //delete the image file
  const handleFileDelete = (imageType: any) => {
    if (imageType === "page") {
      setSelectedPageImage(null);
    } else {
      setSelectedFeedImage(null);
    }
  };

  const handlePageImageChange = (imageType: any, image: any) => {
    if (imageType === "page") {
      setSelectedPageImage(image);
    } else {
      setSelectedFeedImage(image);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        marginTop: "5.73vh",
        padding: "0 4.6vw",
        overflowY: "scroll",
      }}
    >
      <div className="modelHeading-oi9">
        {" "}
        {!viewBenefit ? "Edit Benefit" : "View Benefit"}
      </div>
      <div className="mt-4 row">
        <div className="col-6">
          <label className="form-label">Benefit Name *</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-100 input-value-field mt-2"
            name="benefit name"
            placeholder="Name of the benefit"
            maxLength={50}
            disabled={viewBenefit}
          />
          {formSubmitted && !name && (
            <div className="my-1 error-text-asd errorField">
              *Benefit name required
            </div>
          )}
        </div>
        <div className="col-6">
          <label className="form-label">Status *</label>
          <div className="status-wrap mt-2">
            <SelectWrapper
              options={statusDropdown}
              value={status}
              setValue={setStatus}
              placeholder="Please Select Status"
              disabled={viewBenefit}
            />
          </div>
          {formSubmitted && !status && (
            <div className="my-1 error-text-asd errorField">
              *Status required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <label className="form-label">Benefit Description *</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="text-area-2 p-2"
            placeholder="Description of the benefit"
            maxLength={999}
            disabled={viewBenefit}
          />
          {formSubmitted && !description && (
            <div className="my-1 error-text-asd errorField">
              *Benefit description required
            </div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6 categories-wrapper">
          <label className="form-label">Categories *</label>
          <SelectWrapper
            options={categories}
            value={category}
            setValue={setCategory}
            placeholder="Choose benefit categories"
            disabled={viewBenefit}
          />
          {formSubmitted && !category && (
            <div className="my-1 error-text-asd errorField">
              *Category required
            </div>
          )}
        </div>
        <div className="d-flex flex-column col-6 event-wrapper">
          <label className="form-label">Type of Benefit *</label>
          <SelectWrapper
            options={benefit_types}
            value={type}
            setValue={setType}
            placeholder="Choose benefit type"
            disabled={viewBenefit}
          />
          {formSubmitted && !type && (
            <div className="my-1 error-text-asd errorField">
              *Type of benefit required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3">
                <div className="d-flex flex-column col-12 categories-wrapper">
                <label className="form-label">Available to *</label>
                            <MultiSelectWrapper
                              value={benefitAvailableTo}
                              options={event_available}
                              disabled={viewBenefit}
                              placeholder="Select benefit access for members"
                              setValue={setBenefitAvailableTo}
                            />
                            {formSubmitted && benefitAvailableTo.length === 0 && (
                              <div className="my-1 error-text-asd errorField">
                                *Available to required
                              </div>
                            )}
              </div>
              </div>

      <div className="row mt-3">
        <div className="col-6 d-flex flex-column justify-content-start">
          <label className="form-label">Benefit Page Image *</label>
          <div className="img-desc-cni2 mb-3">
            This will be shown as the main image on your offer feed in the app.
            {/* Size 320px x 480px. */}
          </div>

          <div>
            {selectedPageImage && typeof selectedPageImage === "string" ? (
              <div>
                <img
                  className="selected-page-image"
                  src={selectedPageImage}
                  alt="Selected Page Image"
                />
              </div>
            ) : selectedPageImage && selectedPageImage.path ? (
              <div>
                <img
                  className="selected-page-image"
                  src={selectedPageImage.path}
                  alt="Selected Page Image"
                />
              </div>
            ) : (
              <div></div>
            )}
            {!viewBenefit && (
              <EventImage
                required={true}
                text="Benefit Page"
                formSubmitted={formSubmitted}
                imageSize={{ width: 320, height: 480 }}
                imageType="page"
                onImageSelected={onImageSelected}
                fileDelete={handleFileDelete}
                fileName={
                  selectedPageImage
                    ? typeof selectedPageImage === "string"
                      ? processLogoUrl(selectedPageImage)
                      : selectedPageImage.name
                    : ""
                }
                value={selectedPageImage}
                setValue={handlePageImageChange}
                disabled={viewBenefit}
              />
            )}
          </div>
        </div>

        <div className="col-6 d-flex flex-column justify-content-start ">
          <label className="form-label">Benefit Feed Image *</label>
          <div className="img-desc-cni2 mb-3">
            This will be shown as the primary image on the benefit page in the
            app.
            {/* Size 300px x 360px. */}
          </div>
          <div>
            {selectedFeedImage && typeof selectedFeedImage === "string" ? (
              <div>
                <img
                  className="selected-page-image"
                  src={selectedFeedImage}
                  alt="Selected Feed Image"
                />
              </div>
            ) : selectedFeedImage && selectedFeedImage.path ? (
              <div>
                <img
                  className="selected-page-image"
                  src={selectedFeedImage.path}
                  alt="Selected Feed Image"
                />
              </div>
            ) : (
              <div></div>
            )}
            {!viewBenefit && (
              <EventImage
                required={true}
                text="Benefit Feed"
                formSubmitted={formSubmitted}
                imageSize={{ width: 300, height: 360 }}
                imageType="feed"
                onImageSelected={onImageSelected}
                fileDelete={handleFileDelete}
                fileName={
                  selectedFeedImage
                    ? typeof selectedFeedImage === "string"
                      ? processLogoUrl(selectedFeedImage)
                      : selectedFeedImage.name
                    : ""
                }
                value={selectedFeedImage}
                setValue={handlePageImageChange}
                disabled={viewBenefit}
              />
            )}
          </div>
        </div>
      </div>

      <div className="editModalSubHeading-kid mt-5">Benefit Details</div>
      <div className="line-spreader-sd mt-4"></div>

      <div className="row mt-3">
        <div className="col-6">
          <label className="form-label">Benefit Date(s) *</label>
          <DatePicker
            toggleCalendarOnIconClick
            showIcon
            icon={calenderIcon()}
            placeholderText="MM.DD.YY - MM.DD.YY"
            dateFormat="MM-dd-yy"
            minDate={new Date()}
            selectsRange={true}
            startDate={selectedStartDate}
            disabled={viewBenefit}
            endDate={selectedEndDate}
            onChange={(update: any) => {
              if (update) {
                setSelectedStartDate(update[0]);
                setSelectedEndDate(update[1]);
                setDateRange(update);
              } else {
                setDateError("Benefit date required");
              }
            }}
          />
          {dateError && (
            <span className="error-color d-block">{dateError}</span>
          )}
        </div>

        <div className="benefits-categories-wrapper benefit-online col-6">
          <label className="form-label">Is the benefit online? *</label>
          <SelectWrapper
            options={onlineOptions}
            value={isOnline}
            setValue={setIsOnline}
            placeholder="Please choose"
            disabled={viewBenefit}
          />
          {formSubmitted && !isOnline && (
            <div className="my-1 error-text-asd errorField">
              *Benefit required
            </div>
          )}
        </div>
      </div>
      {/* if benefit is online */}
      {isOnline === "Yes" && (
        <div className="benefit-url position-relative row mb-4 mt-4">
          <div className="col-12">
            <label className="form-label">Benefit Url *</label>
            <input
              disabled={viewBenefit}
              type="url"
              value={locationInfo.benefitUrl}
              onChange={(e) => {
                const { name, value } = e.target;
                setLocationInfo((prevState) => ({
                  ...prevState,
                  [name]: value,
                }));
                const isValidUrl =
                  value.startsWith("http://") || value.startsWith("https://");
                if (!isValidUrl) {
                  setUrlError("Please enter a valid URL");
                } else {
                  setUrlError("");
                }
              }}
              name="benefitUrl"
              className="input-value-field w-100"
              placeholder="Benefit Url"
            />
          </div>
          {formSubmitted && !locationInfo?.benefitUrl && (
            <div className="my-1 error-text-asd errorField">
              Benefit Url required
            </div>
          )}
          {urlError && (
            <div className="my-1 error-text-asd errorField">{urlError}</div>
          )}
        </div>
      )}
      {/* if benefit is physical */}
      {isOnline === "No" && (
        <div>
          <div className="row">
            <div className="position-relative col-12 mt-4">
              <label className="form-label">Benefit Address *</label>
              <span className="search-icon-illusion">{searchIcon()}</span>
              <GooglePlacesAutocomplete
                apiKey={API_KEY}
                selectProps={{
                  isDisabled: viewBenefit,
                  placeholder: "Search Address",
                  defaultValue: rawAddress,
                  onChange: (values) => {
                    setValue(values);
                  },
                  styles: customStyles,
                  isClearable: true
                }}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="address-line-1 col-6">
              <input
                type="text"
                value={locationInfo.addressLine1}
                onChange={handleLocationChange}
                name="addressLine1"
                className="input-value-field w-100"
                placeholder="Address line one"
                disabled={viewBenefit}
              />
              {formSubmitted && !locationInfo?.addressLine1 && (
                <div className="my-1 error-text-asd errorField">
                  Address line one required
                </div>
              )}
            </div>

            <div className="address-line-2 col-6">
              <input
                type="text"
                value={locationInfo.addressLine2}
                onChange={handleLocationChange}
                name="addressLine2"
                className="input-value-field w-100"
                placeholder="Address line two"
                disabled={viewBenefit}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="city col-6">
              <input
                type="text"
                value={locationInfo.city}
                onChange={handleLocationChange}
                name="city"
                className="input-value-field w-100"
                placeholder="City"
                disabled={viewBenefit}
              />
              {formSubmitted && !locationInfo?.city && (
                <div className="my-1 error-text-asd errorField">
                  City required
                </div>
              )}
            </div>

            <div className="country col-6">
              <SelectWrapper
                options={countries}
                value={selectedCountries}
                setValue={setSelectedCountries}
                placeholder="Country"
                disabled={viewBenefit}
              />
              {formSubmitted && !selectedCountries && (
                <div className="my-1 error-text-asd errorField">
                  Country required
                </div>
              )}
            </div>
          </div>

          <div className="event-form-wrapper row mt-3">
            <div className="post-code col-6">
              <input
                type="text"
                value={locationInfo.postalCode}
                onChange={handleLocationChange}
                name="postalCode"
                className="input-value-field w-100"
                placeholder="Post or ZIP Code"
                disabled={viewBenefit}
              />
              {formSubmitted && !locationInfo?.postalCode && (
                <div className="my-1 error-text-asd errorField">
                  postal code required
                </div>
              )}
              <div className="my-1 error-text-asd errorField">{postError}</div>
            </div>
            <div className="longitude col-6">
              <input
                className="input-value-field w-100"
                type="text"
                disabled={viewBenefit}
                id="coordinates"
                placeholder="Longitude, Latitude"
                onChange={handleLocationChange}
                value={
                  locationInfo.longitudes !== null &&
                  locationInfo.latitudes !== null &&
                  locationInfo.longitudes !== undefined &&
                  locationInfo.latitudes !== null
                    ? `${locationInfo.longitudes}, ${locationInfo.latitudes}`
                    : ""
                }
                
              />
              {formSubmitted && !locationInfo?.longitudes && (
                <div className="my-1 error-text-asd errorField"></div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="row mt-4 mb-4">
        <div className="col-lg-6">
          <div className="gap-4 d-flex justify-content-start align-items-center toggle-button-wrap-3">
            <div className="feature-campaign-de3">Featured Benefits</div>
            <div className="toggle-button-wrap f-edit-benefit">
              <Toggle
                id="cheese-status"
                data-testid="cheese-status"
                defaultChecked={isToggled}
                icons={false}
                onChange={handleToggle}
                disabled={viewBenefit}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="ticket-footer-wrapper">
            <div className="ticket-back">
              <Button
                title={viewBenefit ? "EXIT" : "CANCEL"}
                type={"button"}
                backgroundColor="#1A1A1C"
                color="white"
                border="1px solid white"
                borderRadius="6px"
                padding="10.12px 47.3px"
                fontSize="12px"
                fontWeight="600"
                onButtonClick={handleClose}
              />
            </div>
            {!viewBenefit && (
              <div className="ticket-finish">
                <button
                  type="submit"
                  onClick={handleUpdateBenefit}
                  className="benefit-submit-button"
                  disabled={viewBenefit || loading}
                >
                  {loading === true ? <Loader /> : "FINISH"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBenefitModal;
