export const approvedMembers = [
  { name: "USER", style: { minWidth: "120px" }, key: "firstName" },
  { name: "JOB", style: { minWidth: "120px" }, key: "job" },
  { name: "PHONE NUMBER", style: { minWidth: "120px" }, key: "phoneNo" },
  { name: "GENDER", style: { minWidth: "60px" }, key: "gender" },
  { name: "LOCATION", style: { minWidth: "90px" }, key: "primaryCity" },
  { name: "INSTAGRAM", style: { minWidth: "120px" }, key: "instagram" },
  { name: "LINKEDIN", style: { minWidth: "120px" }, key: "linkedin" },
  { name: "APPROVED", style: { minWidth: "100px" }, key: "approvedDate" },
  { name: "STATUS", style: { minWidth: "100px" }, key: "userStatus" },
];
