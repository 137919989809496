/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from "axios";
import api from "./apiService";
import { DocumentFormData } from "@/@Interfaces/BusinessStepFourProps";
const BK_URL_TWO = process.env.REACT_APP_BASE_BK_URL_TWO;

export const get = async (
  endpoint: string,
): Promise<AxiosResponse<any>> => {

    const response = await api.get<any>(endpoint);
    return response;
};

export const get_3 = async (
  endpoint: string,
  data: any
): Promise<AxiosResponse<any>> => {
    const response = await api.get<any>(endpoint, data);
    return response;
};



export const post = async (
  endpoint: string,
  data: any
): Promise<AxiosResponse<any>> => {
    const response = await api.post<any>(endpoint, data);
    return response;
};

export const patch = async (
  endpoint: string,
  data: any
): Promise<AxiosResponse<any>> => {
    const response = await api.patch<any>(endpoint, data);
    return response;
};

export const patch_2 = async (
  endpoint: string,
  data: any
): Promise<AxiosResponse<any>> => {
  try {
    const response = await api.patch<any>(endpoint, data);
    return response;
  } catch (error) {
    throw new Error("An error occurred.");
  }
};


export const post_2 = async (
  endpoint: string,
  data: any
): Promise<Blob> => {
    const response: AxiosResponse<any> = await api.post<any>(endpoint, data, {
      headers: {
        'Content-Type': 'application/json' 
      }
    });
    const blobData = new Blob([response.data], { type: response.headers['content-type'] });

    return blobData;
}

export const get_2 = async (
  endpoint: string,
): Promise<Blob> => {
  try {
    const response: AxiosResponse<any> = await api.get<any>(endpoint);
    const blobData = new Blob([response.data], { type: response.headers['content-type'] });

    return blobData;
  } catch (error) {
    throw new Error("An error occurred.");
  }
}

export const uploadfile = async (
  endpoint: string,
  file: File
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    const response = await fetch(`${BK_URL_TWO}/${endpoint}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "*/*",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to upload file: ${response.status} - ${response.statusText}`
      );
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error("An error occurred.");
  }
};


const dataURLtoFile = (dataurl: string, filename: any) => {
  try{
    const arr = dataurl.split(",");
  const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
  }
  catch (error: any) {
    throw new Error("An error occurred.", error); // Rethrow the error to propagate it
  }
};

export const uploadImages = async (
  endpoint: string,
  fileData: any
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("type", fileData.type);
    formData.append("id", fileData.id);

    // Check if feedImage exists and add it to the form data
    if (fileData.feedImage) {
      const feedImage = dataURLtoFile(
        fileData.feedImage.path,
        fileData.feedImage.name
      );
      formData.append("feedImage", feedImage);
    }

    // Check if pageImage exists and add it to the form data
    if (fileData.pageImage) {
      const pageImage = dataURLtoFile(
        fileData.pageImage.path,
        fileData.pageImage.name
      );
      formData.append("pageImage", pageImage);
    }

    if (fileData.videoUrl) {
      const videoUrl = dataURLtoFile(
        fileData.videoUrl.path,
        fileData.videoUrl.name
      );
      formData.append("videoUrl", videoUrl);
    }

    if (fileData.venueImage) {
      const venueImage = dataURLtoFile(
        fileData.venueImage.path,
        fileData.venueImage.name
      );
      formData.append("venueImage", venueImage);
    }

    const response = await axios.post(endpoint, formData, {
      headers: {
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("An error occurred.");
  }
};



export const uploadBusinessLogo = async (
  endpoint: string,
  fileData: any
): Promise<any> => {
    return new Promise((resolve, reject) => {
      const pageImage = dataURLtoFile(fileData.logo.path, fileData.logo.name);
      const formData = new FormData();
      formData.append("type", fileData.type);
      formData.append("id", fileData.id);
      formData.append("logo", pageImage);

      fetch(endpoint, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
};


export const createBenefitsAPI = async (
  endpoint: string,
  benefitData: any,
  feedImage: any,
  pageImage: any
): Promise<any> => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const formData = new FormData();
    Object.entries(benefitData).forEach(([key, value]) => {
      if (key === 'availableTo' && Array.isArray(value)) {
        // Convert array to the desired string format
        formData.append(key, JSON.stringify(value));
      } 
      else {
        formData.append(key, value as string | Blob);
      } 
    });
    
    const fImg=dataURLtoFile(feedImage.path,feedImage.name)
    const pImg=dataURLtoFile(pageImage.path,pageImage.name)
  

    formData.append('feedImage',fImg);
    formData.append('pageImage', pImg);
    
    const response = await api.post(endpoint, formData, {
      headers: {
        Accept: "*/*",
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("An error occurred.");
  }
};


export const deleteAPI = async (
  endpoint: string,
  data: any
): Promise<AxiosResponse<any>> => {
    const response = await api.delete<any>(endpoint, data);
    return response;
};


export const deleteData = async (
  endpoint: string,
): Promise<AxiosResponse<any>> => {
    const response = await api.delete<any>(endpoint);
    return response;
};


export const uploadDocuments = async (endpoint: string, documents: DocumentFormData[]) => {

  try {
    const formData = new FormData();
    // Loop through each document in the documents array
    documents.forEach((doc: any, index: any) => {
      if (doc) {
        const docFile = dataURLtoFile(doc.file.file.path, doc.file.name);
        formData.append(`documents[${index}][file]`, docFile);
      }
    });

    const response = await api.post(endpoint, formData, {
      headers: {
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error("An error occurred.", error);
    
  }
};

export const uploadEditDocuments = async (endpoint: string, documents: DocumentFormData[]) => {
  try {
    const formData = new FormData();
    // Loop through each document in the documents array
    documents.forEach((doc: any, index: any) => {
      if (doc) {
        const docFile = dataURLtoFile(doc.file.path, doc.file.name);
        formData.append(`documents[${index}][file]`, docFile);
      }
    });

    const response = await api.post(endpoint, formData, {
      headers: {
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error("An error occurred.", error);
    
  }
};